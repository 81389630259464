/* Breakpoints */
$xxl-desktop:1536px;
$xl-desktop: 1280px;
$desktop: 1024px;
$tablet: 768px;
$mobile-large: 640px;
$mobile: 480px;
$mobile-small: 300px;

/*Some other variables*/
$base-color: #261a36;
$secondary-color: #4f5d75;

